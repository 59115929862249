<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
          w-reset
          mb-sm-2
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.organization") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          justify-content-end
          w-reset
        "
      >
        <el-button
          v-can="'organizations.create'"
          size="mini"
          @click="drawerCreate = true"
          icon="el-icon-circle-plus-outline"
        >
          {{ $t("message.create") }}
        </el-button>
        <export-excel
          v-can="'organizations.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Организация"
          name="Организация.xls"
        >
          <el-button size="mini">
            <i class="el-icon-document-delete"></i> Excel
          </el-button>
        </export-excel>
        <crm-column-settings
          :columns="columns"
          :modelName="'organizations'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.company_name"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.phone"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.code"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.fax"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.email"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.address"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.inn"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.mfo"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.okonx"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.oked"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.rkp_nds"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.updated_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.company_name.show">
            <el-input
              size="mini"
              v-model="filterForm.company_name"
              :placeholder="columns.company_name.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.phone.show">
            <el-input
              size="mini"
              v-model="filterForm.phone"
              :placeholder="columns.phone.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.code.show">
            <el-input
              size="mini"
              v-model="filterForm.code"
              :placeholder="columns.code.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.fax.show">
            <el-input
              size="mini"
              v-model="filterForm.fax"
              :placeholder="columns.fax.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.email.show">
            <el-input
              size="mini"
              v-model="filterForm.email"
              :placeholder="columns.email.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.address.show">
            <el-input
              size="mini"
              v-model="filterForm.address"
              :placeholder="columns.address.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.inn.show">
            <el-input
              size="mini"
              v-model="filterForm.inn"
              :placeholder="columns.inn.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.mfo.show">
            <el-input
              size="mini"
              v-model="filterForm.mfo"
              :placeholder="columns.mfo.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.okonx.show">
            <el-input
              size="mini"
              v-model="filterForm.okonx"
              :placeholder="columns.okonx.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.oked.show">
            <el-input
              size="mini"
              v-model="filterForm.oked"
              :placeholder="columns.oked.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.rkp_nds.show">
            <el-input
              size="mini"
              v-model="filterForm.rkp_nds"
              :placeholder="columns.rkp_nds.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.updated_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr
          v-for="organization in list"
          :key="organization.id"
          class="cursor-pointer"
        >
          <td v-if="columns.id.show">{{ organization.id }}</td>
          <td v-if="columns.company_name.show">
            {{ organization.company_name }}
          </td>
          <td v-if="columns.phone.show">{{ organization.phone }}</td>
          <td v-if="columns.code.show">{{ organization.code }}</td>
          <td v-if="columns.fax.show">{{ organization.fax }}</td>
          <td v-if="columns.email.show">{{ organization.email }}</td>
          <td v-if="columns.address.show">{{ organization.address }}</td>
          <td v-if="columns.inn.show">{{ organization.inn }}</td>
          <td v-if="columns.mfo.show">{{ organization.mfo }}</td>
          <td v-if="columns.okonx.show">{{ organization.okonx }}</td>
          <td v-if="columns.oked.show">{{ organization.oked }}</td>
          <td v-if="columns.rkp_nds.show">{{ organization.rkp_nds }}</td>
          <td v-if="columns.updated_at.show">
            {{ organization.updated_at }}
          </td>
          <td v-if="columns.created_at.show">
            {{ organization.created_at }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <crm-setting-dropdown
              :model="organization"
              name="organizations"
              :actions="actions"
              @edit="edit"
              @delete="destroy"
            >
            </crm-setting-dropdown>
          </td>
        </tr>
      </transition-group>
    </table>
    <el-drawer
      title="Новый тег"
      :visible.sync="drawerCreate"
      size="60%" :wrapperClosable="false"
      :drawer="drawerCreate"
      @close="reloadIfChanged"
    >
      <div>
        <crm-create
          :reloadModel="reopenUpdate"
          @c-close="reloadIfChanged('drawerCreate')"
        ></crm-create>
      </div>
    </el-drawer>
    <el-drawer
      title="Изменить тег"
      :visible.sync="drawerUpdate"
      size="80%" :wrapperClosable="false"
      :drawer="drawerUpdate"
      @opened="drawerOpened()"
      @open="reopenUpdate = true"
      @close="reloadIfChanged('reopenUpdate')"
      @closed="emptyModel"
    >
      <div>
        <crm-update
          ref="updateComponent"
          :reloadModel="reopenUpdate"
          @open="reopenUpdate = true"
          @c-close="closeDrawer"
        ></crm-update>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import patientTable from "./components/include/patient-table.vue";
import list from "@/utils/mixins/list";
export default {
  mixins: [list],
  name: "OrganizationsList",
  components: {
    CrmUpdate,
    CrmCreate,
    patientTable,
  },
  data() {
    return {
      drawerCreate: false,
      drawerUpdate: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "organizations/list",
      columns: "organizations/columns",
      pagination: "organizations/pagination",
      filter: "organizations/filter",
      sort: "organizations/sort",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {},
  async mounted() {
    this.controlExcelData();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "organizations/index",
      updateSort: "organizations/updateSort",
      updateFilter: "organizations/updateFilter",
      updateColumn: "organizations/updateColumn",
      updatePagination: "organizations/updatePagination",
      editModel: "organizations/show",
      empty: "organizations/empty",
      delete: "organizations/destroy",
      refreshData: "organizations/refreshData",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    drawerOpened() {
      this.$refs.updateComponent.refreshpatientData();
      this.$refs.updateComponent.refreshcontractData();
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {
          this.drawerUpdate = true;
        })
        .catch((err) => {});
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
