<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.update_condition") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <div class="modal-body">
          <el-form
            ref="form"
            status-icon
            :model="form"
            :rules="rules"
            :label-position="'left'"
          >
            <el-row :gutter="20">
              <el-col :sm="12">
                <el-form-item
                  size="mini"
                  :label="columns.number.title"
                  prop="number"
                >
                  <el-input v-model="form.number" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="12">
                <el-form-item
                  size="mini"
                  :label="columns.organization_id.title"
                  prop="organization_id"
                >
                  <el-select
                    disabled
                    v-model="form.organization_id"
                    :placeholder="columns.organization_id.title"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in list"
                      :key="item.id + item.company_name"
                      :label="item.company_name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :sm="12">
                <el-form-item
                  size="mini"
                  :label="columns.begin_date.title"
                  prop="begin_date"
                >
                  <el-date-picker
                    type="date"
                    v-model="form.begin_date"
                    :placeholder="columns.begin_date.title"
                    style="width: 100%"
                    :format="'dd.MM.yyyy'"
                    :value-format="'dd.MM.yyyy'"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :sm="12">
                <el-form-item
                  size="mini"
                  :label="columns.end_date.title"
                  prop="end_date"
                >
                  <el-date-picker
                    type="date"
                    v-model="form.end_date"
                    :placeholder="columns.end_date.title"
                    style="width: 100%"
                    :format="'dd.MM.yyyy'"
                    :value-format="'dd.MM.yyyy'"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :sm="12">
                <el-form-item size="mini" :label="columns.sum.title" prop="sum">
                  <el-input v-model="form.sum" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="12">
                <el-form-item
                  size="mini"
                  :label="columns.remainder.title"
                  prop="remainder"
                >
                  <el-input
                    v-model="form.remainder"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="12">
                <el-form-item
                  size="mini"
                  :label="columns.status_id.title"
                  prop="status_id"
                >
                  <el-input
                    v-model="form.status_id"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="12">
                <el-form-item
                  size="mini"
                  :label="columns.parent_id.title"
                  prop="parent_id"
                >
                  <el-input
                    v-model="form.parent_id"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  size="mini"
                  :label="columns.comment.title"
                  prop="comment"
                >
                  <el-input
                    v-model="form.comment"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-divider>{{ $t("message.contract_service") }}</el-divider>
              </el-col>
              <el-col :span="24">
                <div class="custom-tree-container">
                  <div class="block">
                    <el-tree
                      class="filter-tree"
                      :data="service_list"
                      show-checkbox
                      node-key="id"
                      :props="defaultProps"
                      @check-change="selected"
                      :filter-node-method="filterNode"
                      ref="items"
                      :default-checked-keys="default_checked_services"
                      :expand-on-click-node="true"
                    >
                      <span
                        class="custom-tree-node"
                        slot-scope="{ node, data }"
                      >
                        <span>{{ data.name }}</span>
                        <span
                          class="tree-meta-price"
                          v-if="data.children.length == 0"
                        >
                          {{ data.price | formatMoney }}
                        </span>
                      </span>
                    </el-tree>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
export default {
  mixins: [form],
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "label",
        price: "price",
      },
      selectedList: [],
      default_checked_services: [],
    };
  },
  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  created() {
    this.ServiceList();
  },
  computed: {
    ...mapGetters({
      list: "organizations/list",
      rules: "contracts/rules",
      model: "contracts/model",
      columns: "contracts/columns",
      service_list: "services/relation_list",
    }),
  },
  methods: {
    ...mapActions({
      save: "contracts/update",
      servicesRealtionList: "services/relationList",
    }),
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
      let services = this.form.services;

      this.default_checked_services = [];
      for (let key in services) {
        if (services.hasOwnProperty(key)) {
          this.default_checked_services.push(services[key].id);
        }
      }
      this.$refs.items.setCheckedKeys(this.default_checked_services);
    },
    ServiceList() {
      const query = { relation: true };
      this.servicesRealtionList(query)
        .then((res) => {
          let services = this.form.services;
          this.default_checked_services = [];
          for (let key in services) {
            if (services.hasOwnProperty(key)) {
              this.default_checked_services.push(services[key].id);
            }
          }
          this.$refs.items.setCheckedKeys(this.default_checked_services);
        })
        .catch((err) => {});
    },
    submit(close = true) {
      let checkedServices = this.$refs.items.getCheckedNodes();
      let perms = [];
      for (let key in checkedServices) {
        if (checkedServices.hasOwnProperty(key)) {
          let checkedPermission = checkedServices[key];
          perms.push(checkedPermission.id);
        }
      }
      this.form["services"] = perms;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              this.resetForm("form");
              this.parent().listChanged();
              if (close)
                this.$emit("c-close", { reload: true, drawer: "drawerUpdate" });
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            });
        }
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.indexOf(value) !== -1;
      return dd;
    },
    selected() {
      this.selectedList = [];
      let item = this.$refs.items.getCheckedNodes();
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          if (element.children.length == 0) {
            this.selectedList.push(element);
          }
        }
      }
    },
    pluckId() {
      let ids = [];
      for (let index = 0; index < this.selectedList.length; index++) {
        const element = this.selectedList[index];
        ids.push(element.id);
      }
      return ids;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
  },
};
</script>
